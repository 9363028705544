import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../components/layout/Layout";

import styles from "./pages.module.scss";
import BodyHeader from "../components/body-header/BodyHeader";

export const query = graphql`{
  privacy: mdx(
    fileAbsolutePath: {regex: "src/data/copy/"}
    frontmatter: {title: {eq: "Privacy & Cookies"}}
  ) {
    frontmatter {
      image {
        childImageSharp {
          gatsbyImageData(quality: 80, formats: [WEBP, JPG], layout: FULL_WIDTH)
        }
      }
      title
      category
      excerpt
      date(formatString: "MMM Do, YYYY")
      dateSimple: date(formatString: "YYYY-MM-DD")
      lastUpdate(formatString: "MMM Do, YYYY")
      lastUpdateSimple: date(formatString: "YYYY-MM-DD")
    }
    body
  }
}
`

const Privacy = ( props ) => {

    const { body, frontmatter } = props.data.privacy;

    return (
        <Layout
            location={props.location}
            title={frontmatter.title}
            type={"legal"}
        >
            <div className={styles.body}>
                <BodyHeader
                    hideBreadcrumb
                    frontmatter={frontmatter}
                />
                <MDXRenderer>{body}</MDXRenderer>
            </div>
        </Layout>
    )
}

export default Privacy;